<template>
    <TaxaArmazenagem :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    import TaxaArmazenagem from '../../components/extrato/TaxaArmazenagem.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { TaxaArmazenagem }
    }

</script>
<template>
    <div class="col-12">
        <div class="card">
            <h5>Taxa de Armazenagem</h5>			

            <DataTable :value="database" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['placa','nrRe','imovel','produto','nrDocPes']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>

				<Column field="dtEntrada" header="Data Entrada" :sortable="true">
					<template #body="data">
                        {{formatDate(data.data.dtEntrada)}}
					</template>
				</Column>
                <Column field="dtInicio" header="Início cobrança" :sortable="true" style="text-align: center">
					<template #body="data">
                        {{formatDate(data.data.dtInicio)}}
					</template>
				</Column>
                <Column field="placa" header="Placa" :sortable="true" style="text-align: center">
					<template #body="data">
                        {{data.data.placa}}
					</template>
				</Column>
                <Column field="imovel" header="Imóvel" :sortable="true" style="text-align: left">
					<template #body="data">
                        {{data.data.imovel}}
					</template>
				</Column>
                <Column field="produto" header="Produto" :sortable="true" style="text-align: left">
					<template #body="data">
                        <span>{{data.data.produto}}</span>
					</template>
				</Column>
                <Column field="nrDocPes" header="Doc. Pes." :sortable="true" style="text-align: center">
					<template #body="data">
                        {{data.data.nrDocPes}}
					</template>
				</Column>
                <Column field="nrRe" header="Núm. RE" :sortable="true" style="text-align: center">
					<template #body="data">
                        {{data.data.nrRe}}
					</template>
				</Column>
                <Column field="pesoBruto" header="Peso bruto" :sortable="true" style="text-align: right">
					<template #body="data">
                       {{formatDecimal(data.data.pesoBruto, 0, 0)}}
					</template>
				</Column>
                <Column field="rendaLiquida" header="Renda Liquida" :sortable="true" style="text-align: right">
					<template #body="data">
                        {{formatDecimal(data.data.rendaLiquida, 0, 0)}}
					</template>
				</Column>
                <Column field="txArmazem" header="Taxa Armazém" :sortable="true" style="text-align: right">
					<template #body="data">
                        {{data.data.txArmazem}}
					</template>
				</Column>              
			</DataTable>
            
        </div>
    </div>

</template>

<script>
    import {FilterMatchMode} from 'primevue/api';
    import TaxaArmazenagem from "../../service/TaxaArmazenagem"
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'Taxa Armazenagem', 

		props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data(){
            return {
                database: [],
				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,                
            }
        },
        created(){
            this.initFilters1();
            this.getTaxaArmazenagem();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'placa': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'nrRe': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'imovel': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'produto': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'nrDocPes': {value: null, matchMode: FilterMatchMode.CONTAINS}
				}
			},
            getTaxaArmazenagem() {
                TaxaArmazenagem.getArmazenagem(this.$props.matricula).then((response) => {
                    this.database = response.data;
				});
			},
			clearFilter1() {
				this.initFilters1();
			},
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			},
			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			}
        }       
    }
    
</script>
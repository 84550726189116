import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const TAXA_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/inicio-cobranca`;

class TaxaArmazenagemService {

    getArmazenagem(matricula) {
        return axios.get(TAXA_API_BASE_URL + RESOURCE_V1 + "/" + matricula);
    }
}

export default new TaxaArmazenagemService();